<template>
    <div class="main-wrapper">
        <div class="row" style="background-color: #033A5F; padding:20px">
            <img src="../../../public/assets/images/logo.svg" width="200" height="200" style="margin-left:40%">
        </div>
        <div class="container content-holder banner-holder" style="padding:20px">
            <h2>Privacy Policy</h2>
            <p>When you apply for or maintain a live or demo account with AAAFx, we collect personal information about you for business purposes, such as evaluating your financial needs, processing your requests and transactions, informing you about products and services that may be of interest to you, and providing client service. Such information may include:</p>
            <p>Application Information - Information you provide to us on applications and other forms, such as your name, address, birth date, social security number, occupation, assets, and income.</p>
            <p>Transaction Information - Information about your transactions with us and with our affiliates as well as information about our communications with you. Examples include your account balances, trading activity, your inquiries and our responses.</p>
            <p>Verification Information - Information necessary to verify your identity such as a passport or drivers license. Examples also include background information about you we receive from public records or from other entities not affiliated with AAAFx. On the strength of MiFiD directives, AAAFx has to collect information and take necessary actions to verify your identity.</p>
            <p>Updated Privacy Policy compliant with the <a href="https://v1.aaafx.com/Assets/AAAFx/GDPR.pdf?1" target="_blank">General Data Protection Regulation (GDPR)</a>.</p>
            <p>Updated Privacy Policy compliant with <a href="https://v1.aaafx.com/Assets/AAAFx/mifid_V2.pdf" target="_blank">MIFID II</a>.</p>
            <h2>About Cookies</h2>
            <p>Cookies are small files containing information that a Web site uses to track its visitors. AAAFx may set and access AAAFx cookies on your computer, enabling us to learn which advertisements and promotions bring users to our website. AAAFx or any of its divisions may use cookies in connection with AAAFx's products and services and to track your activities on our websites. Such information that we collect and share would be anonymous and not personally identifiable.</p>
            <h2>Security Technology</h2>
            <p>AAAFx uses Secure Socket Layer (SSL) encryption technology in order to protect certain information that you submit. This type of technology protects you from having your information intercepted by anyone other than AAAFx while it is being transmitted to us. We work hard to ensure that our websites are secure and that they meet industry standards. We also use other safeguards such as firewalls, authentication systems (e.g., passwords and personal identification numbers) and access control mechanisms to control unauthorized access to systems and data.</p>
            <h2>Sharing Information With Our Affiliates</h2>
            <p>We may share personal information described above with our affiliates for business purposes, such as servicing client accounts and informing clients about new products and services, and as permitted by applicable law. Our affiliates may include companies controlled or owned by us as well as companies which have an ownership interest in our company. The information we share with affiliates may include any of the information described above, such as your name, address and account information. Our affiliates maintain the privacy of your information to the same extent AAAFx does in accordance with this Policy.</p>
            <h2>Sharing Information With Third Parties</h2>
            <p>AAAFx does not disclose your personal information to third parties, except as described in this Policy. Third party disclosures may include sharing such information with non-affiliated companies that perform support services for your account or facilitate your transactions with AAAFx, including those that provide professional, legal, or accounting advice to AAAFx. Non-affiliated companies that assist AAAFx in providing services to you are required to maintain the confidentiality of such information to the extent they receive it and to use your personal information only in the course of providing such services and only for the purposes that AAAFx dictates. We may also disclose your personal information to third parties to fulfill your instructions or pursuant to your express consent. We want you to know that AAAFx will not sell your personal information.</p>
            <h2>Regulatory Disclosure</h2>
            <p>Under limited circumstances, AAAFx may disclose your personal information to third parties as permitted by, or to comply with, applicable laws and regulations. For example, we may disclose personal information to cooperate with regulatory authorities and law enforcement agencies to comply with subpoenas or other official requests, and as necessary to protect our rights or property. Except as described in this privacy policy, we will not use your personal information for any other purpose unless we describe how such information will be used at the time you disclose it to us or we obtain your permission.</p>
            <h2>Opt Out</h2>
            <p>You are not required to supply any of the personal information that we may request. However, failure to do so may result in our being unable to open or maintain your account or to provide services to you. While we make every effort to ensure that all information we hold about you is accurate, complete and up to date, you can help us considerably in this regard by promptly notifying us if there are any changes to your personal information. If you do not wish to have your personal information disclosed to third parties as described in this Policy, please contact us via e-mail.</p>
            <h2>Candidates Privacy Notice - AAAFx</h2>
            <p><strong><a href="https://v1.aaafx.com/Assets/AAAFx/CandidatesPrivacyNotice.pdf?1" target="_blank">click here...</a></strong></p>
        </div>
    </div>
</template>
<style>
    .main-wrapper{
        width:100%;
        background-color: #EAF5FE;
    }
    .content-holder{
        background-color:#ffff;
        width:60%;
        color: #0D5688
    }
</style>